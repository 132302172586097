/*
* 语言文件 en：英文
* */
export const lang_en = {
  //首页公共头部最顶部
  '您好，欢迎来到': 'Hello, welcome to',
  '民航商城': 'Civil Aviation Mall',
  '退出': 'Quit',
  '注册': 'Register',
  '首页': 'Home',
  '商家大全': 'Merchants',
  '航材交易': 'Material',
  '工装设备': 'Tool & equipment',
  '资源共享': 'Resource',
  '维修服务': 'Maintenance',
  '技术咨询': 'Consulting',
  '政策法规': 'Policies & regulations',
  '民航学苑': 'Civil Aviation Academy',
  '供应商入驻': 'Supplier',
  '服务中心': 'Service Center',
  '商品': 'Goods',
  '供应商': 'Supplier',
  '我的工作台': 'Workbench',
  '简体中文': 'Chinese',
  'English': 'English',
  '网上航展': 'Online Exhibition',
  '机场休闲': 'Terminal Leisure',
  '飞翔俱乐部': 'Fly Club',
  '出行定制': 'Travel Customization',
  '民航商城APP': 'Civil Aviation Mall APP',
  '全部商品分类': 'All Categories',
  '拼命加载中': 'Loading...',
  'APP下载': 'APP Download',
  '扫一扫': 'Take a sweep',


  //登录页面
  '手机登录': 'Phone',
  '账号登录': 'Account',
  '还没注册？': 'Not registered?',
  '去注册': 'To register',
  '账号为4~16位字母、数字或下划线': 'Account number from 4 to 16 letters, digits, or underscores',
  '登录': 'Log in',
  '请输入密码': 'Please enter your password',
  '立即注册': 'Register Now',
  '忘记密码': 'Forgot password',
  '请输入账号/手机号': 'Please enter the account number/ mobile phone number',
  '请输入手机号': 'Please enter phone number',
  '请输入验证码': 'Please enter verification code',
  '获取验证码': 'Get verification code',
  's后获取': 'Obtain in  __ seconds',
  '请输入正确的账号/手机号': 'Please enter the correct account number / mobile phone number',

  //忘记密码页面
  '想起密码？': 'Remember your password?',
  '去登录': 'To log',
  '找回密码': 'Retrieve password',

  '使用民航商城APP扫码登录': 'Use the APP of Civil Aviation Mall to scan the code and log in',
  '二维码已失效': 'Qr code is no longer valid',
  '点击刷新': 'Hit Refresh',
  '登录成功': 'Log in successfully',

  '扫码登录': 'Code scanning login',
  '验证码登录': 'Account login',
  '密码登录': 'Password login',
  '手机/邮箱账号：': 'Mobile phone / email account:',
  '验证码：': 'Code：',
  '输入手机号/邮件账号': 'Enter mobile phone number / email account number',
  '输入账号/手机号/邮件账号': 'Enter account / mobile phone number / email account',
  's后，重新发送': 'Resend after __ seconds',
  '验证码已发送!': 'Verification code has been sent!',
  '请输入正确的手机号/邮件账号': 'Please enter the correct mobile phone number / email account',
  '设置新密码：': 'Set new password:',
  '确认密码：': 'Confirm：',
  '密码修改成功，请牢记密码': 'Password changed successfully, please remember the password',
  '手机号注册': 'Phone registration',
  '邮箱注册': 'Email registration',
  '密码是由6-20位数字字母符号组合': 'The password is a combination of 6-20 digits, letters and symbols',
  '用户名是由6-20位字符组合': 'The user name is a combination of 6-20 characters',
  '用户名：': 'User Name：',
  '手机号：': 'Phone：',
  '密码：': 'Password：',
  '邮箱账号：': 'Email Account：',
  '请输入账号': 'Please enter the account number',
  '请输入邮箱账号': 'Please enter email account',
  '请输入确认密码': 'Please enter the confirmation password',
  '密码与确认密码不一致': 'The password is inconsistent with the confirmation password',
  '我已阅读并同意': 'I have read and agree',
  '《航云商城用户协议和隐私协议》': 'User agreement and privacy agreement',
  '《航云商城用户协议》': 'User agreement',
  '《隐私协议》': 'Privacy agreement',
  '和': 'and',
  '注册成功': 'Register Successful',

  //注册页面
  '已有账号？': 'Have an account?',
  '已有账号，去登录': 'Have an account, log in',
  '注册账号': 'Register an account',
  '请输入图形验证码': 'Please input pattern codes',

  //注册协议页面
  '我已知悉？': 'I understand that?',

  //文章，商品分类
  '暂无数据': 'No data',
  // '首页': '首页',
  '文章': 'article',
  '文章分类': 'Article Category',
  '最新文章': 'Latest articles',
  '搜索': 'Search',
  '默认': 'Default',
  '人气': 'Popular',
  '成交量': 'Volume',
  '请输入店铺名称': 'Please enter the shop name',
  '用户登录': 'User Login',
  '进入工作台': 'Enter Workbench',
  '商家入驻': 'Shops settle in',
  '我的服务': 'My Services',
  '我的采购': 'My Purchases',
  '采购公告': 'Purchase Announcement',
  '更多服务': 'More Services',

  '品牌旗舰店': 'Brand Flagship Shop',
  '质量保证，场景打造': 'Quality assurance, scene creation',
  '选择类目': 'Select Category',
  '最近成交供应商': 'Recently concluded suppliers',
  '本区划下最近成交供应商': 'Suppliers recently concluded under this division',
  '主营': 'Main Business',
  '商品数量': 'Quantity',
  '物流': 'logistics',
  '服务': 'Service',
  '质量': 'Quality',
  '猜你喜欢': 'Guess you like',

  //商品列表
  '综合': 'Comprehensive',
  '评论数': 'Reviews',
  '商品类型': 'Product Types',
  '仅显示有货': 'Available Products Only',
  '仅平台自营': 'Self-platform Products Only',
  '分类': 'Classification',
  '筛选结果': 'Screening results',
  '包含分类': 'Categorical',
  '清空': 'Empty',
  '更多选项': 'More options',

  //商品详情页面
  '自营': 'Self-platform',
  '联系客服': 'Contact Customer Service',
  '已关注': 'Followed',
  '关注店铺': 'Followed Shops',
  '找相似': 'Find similarities',
  '价格': 'Price',
  '销售价': 'Price',
  '销量': 'Sales',
  '优惠券': 'Coupon',
  '促销': 'Promotion',
  '查看详情': 'See details',
  '配送至': 'Ship to',
  '请选择地址': 'Please select the address',
  '数量': 'Number',
  '库存': 'In stock',
  '立即购买': 'Order Now',
  '立即下单': 'Order Now',
  '询价采购': 'Inquiry',
  '购物车': 'Cart',
  '已收藏': 'Favorites',
  '收藏': 'Add favorites',
  '分享': 'Share',
  '看了又看': 'Frequent Review',
  '店铺等级': 'Shop class',
  '商品评价': 'Product review',
  '物流评价': 'Logistics evaluation',
  '售后服务': 'After sale service',
  '进入店铺': 'Enter shop',
  '店铺推荐': 'Shop Recommendations',
  '主营商品': 'Main commodities',
  '高': 'high',
  '低': 'low',
  '中': 'Middle',
  '取消关注': 'Unsubscribe',
  '热门收藏': 'Most favorites',
  '已售': 'sold',
  '商品详情': 'Product details',
  '规格参数': 'Specifications',
  '成交记录': 'Transaction record',
  '用户评价': 'User Reviews',
  '评价': 'Reviews',
  '商品服务': 'Item Service',
  '店铺热销': 'Best Selling',
  '手机下单': 'Order by phone',
  '加入购物车': 'Add to cart',
  '品牌': 'Brand',
  '查看全部': 'View all',
  '收起全部': 'Contract All',
  '评分': 'Score',
  '好评率': 'Favorable rate',
  '全部': 'All',
  '有图': 'See Pictures',
  '好评': 'Praise',
  '中评': 'Average',
  '差评': 'Poor',
  '商品已下架': 'Off the shelf',
  '库存不足': 'Inventory shortage',
  '该商品暂无评论~': 'No comment this product ~',
  '暂无相关商品': 'No related products',
  '库存不足！': 'Inventory shortage!',
  '超过购买上限!': 'Exceed the purchase limit!',
  '未登录，请先登录!': 'Not logged in, please login!',
  '加入购物车成功': 'Add to Cart successfully',
  '暂无商品服务~': 'Service not available',
  '该商品暂无详情~': 'Details not available',
  'QQ': 'QQ',
  '新浪': 'Sina',
  '微信': 'Wechat',
  '分享到微信': 'Share into wechat',
  '请选择规格！': 'Please select Specifications！',
  '该商品不在配置范围': 'This item is not in the distribution scope',


  //购物车页面
  '全选': 'Select all',
  '商品信息': 'Product info',
  '单价': 'Unit price',
  '小计（元）': '(Yuan)',
  '操作': 'Operating',
  '领取优惠券': 'Receive coupons',
  '满减': 'Discount',
  '去凑单': 'Add-on',
  '再逛逛': "I'll look around.",
  '移入收藏夹': 'Add favorites',
  '删除': 'Delete',
  '取消选择': '取消选择',
  '失效商品': 'Goods invalid',
  '失效': 'Invalid',
  '已下架': 'Off the shelf',
  '删除选中的商品': 'Delete selected items',
  '清空失效的商品': 'Delete invalid items',
  '已选择': 'Selected',
  '件商品': 'Number of Items',
  '合计': 'Total',
  '促销减': 'Promotions',
  '结算': 'Settlement',
  '确定清空失效商品？': 'Ready to delete invalid items?',
  '取 消': 'Cancel',
  '确 定': 'Confirm',
  '确定删除选中商品？': 'Ready to delete selected items?',
  '剩余': 'Remain',
  '件': 'Piece',
  '确定': 'Confirm',
  '取消': 'Cancel',
  '最低限购1件!': 'Minimum purchase: 1 piece',
  '数量超出购买范围！': 'Quantity exceeds the purchase limitation',
  '超过购买限制!': 'Exceeds the purchase limitation',
  '不能输入非0数字!': 'Enter integral numbers only',
  '移入收藏夹成功！': 'Add favorites successfully',
  '删除成功！': 'Successfully Deleted!',
  '清空失效商品成功！': 'Delete invalid items successfully',
  '结算商品不能为空！': 'Settlement of items can not be empty',


  //我的收藏
  '我的': 'Mine',
  '收藏的商品': 'Favorites',
  '收藏的店铺': 'Favorite shops',
  '热门推荐': 'Hot Offers',
  '这里空空如也，快去首页逛逛吧~': 'Empty here, have a try at the home page.',
  '去首页 > >': 'To home page',
  '已加入购物车': 'Added to Cart',
  '普通关注': 'Common concern',
  '特别关注': 'Special concern',
  ' 设为特殊关注': 'Set as special concern',
  '取消特殊关注': 'Cancel special concern',
  '本月上新': 'New this month',
  '热销推荐': 'Hot Recommend',
  '取消收藏': 'Unsubscribe',
  '管理': 'Management',
  '设为特殊关注': 'Set special Concerns',
  '您还没关注过店铺，快去': 'You have not been concerned about the store, go',
  '逛逛吧~': 'Stroll ~',
  '您还没关注过商品，快去': 'You have not been concerned about the product, go',


  //首页公共头部——搜索模块
  '请输入关键词': 'Please enter a keyword',
  '热门搜索': 'Popular searches',
  '我的购物车': 'Cart',
  '购物车中还没有商品，赶紧选购吧': 'Shopping cart is still empty, go shopping now',

  //店铺公共头部数据——导航和店铺分类
  '综合评分': 'Overall rating',
  '店铺评分': 'Store Ratings',
  '描述相符': 'Description match',
  '服务态度': 'Service attitude',
  '服务承诺': 'Service Guarantee',
  '正品保障': 'Genuine security',
  '发货速度': 'Delivery speed',
  '客服电话': 'Consumer hotline',
  '店铺首页': 'Store Home',
  '搜本店': 'This store',
  '本店全部分类': 'All Category',
  '所有商品': 'All goods',
  '请输入...': 'Please enter...',

  //店铺商品列表
  '件相关商品': 'Number related products',
  '共': 'Total',

  //首页装修
  '人购买': 'People bought.',
  '立即抢购': 'Buy now',
  '查看更多': 'More',
  '件号/商品号':'Part No./Product No',
  库存地:'Inventory location',
  库存数:'Inventory',
  '此处添加商品': 'Add items here.',
  '此处添加【360*560】图片': 'Add photos [360 * 560]  here.',
  '请选择商品，数量建议选择3的倍数，最少3个，最多12个': 'Select the items, recommended to choose the number of multiples of 3, minmum 3, maximum 12',
  '请上传500*80的图片': 'Please upload pictures of 500 * 80',
  '平台还未设置公告': 'Platform announcement has not been set',
  '平台还未设置资讯': 'Platform information has not been set',
  '该场景还没有商品': 'No items in this scenario.',
  '请选择供货商，数量建议选择5的倍数，最少5个，最多20个': 'Please select the supplier, recommended to choose the number of multiples of 5, minmum 5, maximum 20',


  //我的足迹
  '关注': 'Concerns',

  //个人中心
  '会员收货地址': 'Member shipping address',
  '我的财产': 'My property',
  '余额': 'Balance',
  '充值': 'Recharge',
  '领券': ' Collect coupons',
  '查看': 'View',
  '售后/退货': 'After Sale / Return',
  '进行中的订单': 'Ongoing orders',
  '去支付': 'To pay',
  '我的关注': 'My Concerns',
  '商品关注': 'Item Concerns',
  '店铺关注': 'Shop concern',
  '积分': 'Credit',
  '这里空空如也，快去挑选合适的商品吧~': 'Shopping cart is still empty, go shopping now',
  '退款金额不可为0或者负值！': 'The refund amount cannot be 0 or negative',
  //订单列表
  '订单': 'Orders',
  '商品名称/订单编号': 'Product name / order number',
  '订单号/商品名称/店铺名称': 'Rrder number /product name /  store name',
  '我的订单': 'Order',
  '全部订单': 'All orders',
  '待支付': 'To be paid',
  '待发货': 'to be delivered',
  '待收货': 'Waiting for delivery',
  '已完成': 'Completed',
  '待评价': 'Waiting for comment',
  '请输入内容': 'Please enter content',
  '搜索订单': 'Search for orders',
  '售后维权': 'After sales rights',
  '订单金额': 'Order amount',
  '下单时间': 'Order time',
  '订单号': 'Order number',
  '订单详情': 'Order details',
  '普通订单': 'General orders',
  '修改地址': 'Change address',
  '查看物流': 'View Logistics',
  '确认收货': 'Confirm receipt',
  '确认收货?': 'Confirm receipt?',
  '取消订单': 'Cancel order',
  '立即支付': 'Pay immediately',
  '删除订单': 'Delete orders',
  '取消订单理由': 'Reason to cancel the order',
  '物流信息': 'Logistics information',
  '运单号': 'Waybill number',
  '承运人': 'Carrier',
  '联系人': 'Contacts',
  '联系电话': 'Contact number',
  // '更换地址': 'Replace the address',
  '收货人': 'Receiver',
  // '联系方式': 'contact details',
  // '收货地址': 'Shipping address',
  // '提交修改': 'Submit modification',
  '切换地址成功': 'Address switching success',
  '确认删除该订单?': 'Confirm delete the order?',
  '提示': 'prompt',
  '删除订单成功': 'Delete Orders success',
  '请选择取消理由': 'Select Cancel grounds',
  '取消订单成功': 'Cancel the order success',
  '确认收货成功': 'Confirm receipt success',
  '暂无订单～': 'No orders ~',
  '满优惠': 'Discount',
  '平台优惠券': 'Platform Coupon',
  '店铺优惠券': 'Shop Coupon',
  // 售后详情页面
  '退款退货单号': 'Order number for refund and return',
  '提交申请': 'Submit application',
  '审核中': 'Under review',
  '完成': 'Carry out',
  '退款金额': 'The amount of the refund',
  '问题描述': 'Problem Description',
  '时间': 'Time',
  '平台审核备注': 'Platform audit notes',
  '退款凭证': 'Refund certificate',
  '退款单号': 'Refund No.',

  // 申请售后

  '添加/编辑': 'Add / Edit',
  '（同订单商品可批量申请）': ' (goods of the same order can be applied in batch)',
  '退款金额可修改，最多': 'The refund amount may be modified, Maximum',
  '退款金额不可修改，最多': 'The amount of the refund can not be modified, Maximum',
  '含运费': 'Including freight',
  '不含运费': 'Excluding freight',
  '修改金额': 'Modify the amount',
  '申请件数': 'The number of applications',
  '请输入申请件数': 'Please enter a number of applications',
  '申请类型': 'Application type',
  '货物状态': 'Cargo status',
  '退款原因': 'Reason for return',
  '请选择退款原因': 'Please select the reason for the refund',
  '请输入退款金额': 'Please enter the amount of the refund',
  '请输入问题描述(选填)': 'Please enter a description (optional)',
  '批量售后商品选择': 'After sale service selection',
  '上传凭证': 'Upload certificate',
  '未收到货': 'We did not receive the goods',
  '已收到货': 'We have received the goods',
  '退款退货': 'Refund / return',
  '不可超出最大申请数量': 'Not exceed the maximum number of application',
  '不可超过最大退款金额！': 'Not exceed the maximum amount of the refund',
  '退款金额不可0或者为负值！': 'Refund amount can not be 0 or negative!',
  '最多上传5张！': 'Upload up to five!',


  // 售后列表
  '商品明细': 'Item Details',
  '申请时间': 'Application time',
  '状态': 'Status',
  '发货': 'Ship',
  '仅退款': 'Refund only',
  '退货退款': 'Refund and return',

  // 填写物流页面
  '用户发货': 'Users delivery',
  '商品名称': 'Item Name',
  '购买数量': 'Purchase quantity',
  '服务类型': 'Service type',
  '快递公司': 'Express',
  '请选择快递公司': 'Please select Express',
  '快递单号': 'Tracking number',
  '请输入快递单号': 'Please enter the tracking number',
  '提交': 'Submit',
  '请选择物流公司!': 'Please select logistics company!',
  '请输入物流单号！': 'Please enter the waybill number',
  '请输入正确的物流单号！': 'Please enter the correct waybill number',

  //消息中心——左侧公共菜单
  '消息列表': 'Message List',
  '系统消息': 'System information',
  '订单消息': 'Order message',
  '资产消息': 'Asset news',
  '售后消息': 'Sale message',
  '接收设置': 'Receiving settings',


  //待评订单
  '待评订单': 'Orders to be comment',
  '发评价，得积分，积少成多换商品': 'Comments and get credits, credits accumulated for free orders.',
  '评价小贴士': 'Tips for comments',
  '发表评价可获得会员积分': 'Comments and get credits',
  '支付金额': 'Payment amount',
  '评价订单': 'Order comments',

  //订单详情
  '提交订单': 'Submit Order',
  '支付订单': 'Payment orders',
  '商家发货': 'Merchant shipping',
  '送货方式': 'Delivery methods',
  '物流公司': 'Logistics Company',
  '运单号码': 'Waybill number',
  '暂无物流信息': 'No logistics information',
  '订单信息': 'Order information',
  '发票': 'Invoice',
  '不需要发票': 'Invoices do not need',
  '发票抬头': 'Invoice headers',
  '卖家': 'Sellers',
  '收货人信息': 'Recipient information',
  '商品总额': 'Total orders',
  '商品金额': 'Total Amount',
  '优惠': 'Discount',
  '运费金额': 'The amount of freight',
  '实际金额': 'The actual amount',
  '交易关闭': 'Transaction closed',
  '交易流水号': 'Transaction serial number',
  //领劵中心
  '领劵中心': 'Coupon center',
  '折': 'Discount',
  '立即领取': 'get it right now',
  '已领取': 'Received',
  '已抢': 'Received',
  '已抢光': 'All dispatched',
  '领取成功': 'Receive success',
  //个人中心-我的优惠卷
  '我的优惠卷': 'My coupons',
  '未使用': 'Unused',
  '已使用': 'Used',
  '已失效': 'Expired',
  '使用规则': 'Rules',
  '立即使用': 'Use immediately',
  //个人中心——头部公共菜单
  '消息': 'News',
  '账户管理': 'Account Management',
  '确定删除这些消息吗': 'Are you sure to delete these messages',
  //个人中心——左侧公共菜单
  '交易中心': 'Trading Center',
  '交易评价/晒单': 'Comments/shares',
  '关注中心': ' Concern Center',
  '我的收藏': 'Collection',
  '我的足迹': 'My Tracks',
  '客户服务': 'Customer service',
  '我的退款': 'My refund',
  '我的退货': 'My return',
  '会员中心': 'Membership center',
  '会员信息': 'Member information',
  '账号安全': 'Account security',
  '手机号管理': 'Phone number management',
  '邮箱管理': 'E-mail management',
  '登录密码': 'Login password',
  '支付密码': 'Payment password',
  '重置密码': 'Reset Password',
  '我的钱包': 'My purse',
  '我的余额': 'My balance',
  '账户充值': 'Account recharge',
  '提现账号': 'Account withdrawals',
  '我的优惠券': 'My discount coupon',


  //个人中心-我的余额
  '总金额': 'Total amount',
  '可用余额': 'Available Balance',
  '余额明细': 'Balance details',
  '充值明细': 'Recharge details',
  '交易日期': 'Transaction date',
  '收入/支出': 'Income and expenses',
  '变动原因': 'Reasons for change',
  '充值金额': 'Recharge amount',


  //utils/common
  '请输入正确的手机号': 'please enter a valid phone number',
  '密码最少6位哦～': 'At least 6 digits of password',
  '密码最多20位哦～': 'At most 20 digits of password',
  '密码不可以有中文哦～': 'No Chinese characters in password',
  '密码中不可以有空格哦～': 'No blank space in password',
  '请输入短信验证码': 'Please enter the message authentication code',
  '请输入正确的短信验证码': 'Please enter the correct message authentication code',
  '请输入6～20位的会员名': 'Please enter 6 to 20 member name',
  '请输入6～会员名不能全为数字': 'Username can not be all digital',
  '会员名须由中、英文、数字、"-"及"_"组成': 'Member name shall be composed of by English letters, numbers, "-" and "_"',
  '请输入正确的图形验证码': 'Please enter the correct pattern codes',
  '该功能在升级中～': 'In upgrading.',
  '请输入邮箱': 'Please input your email',
  '请输入正确的邮箱': 'please enter your vaild email',

  // 消息设置
  '接收': 'Receive',
  '不接收': 'Refuse to receive.',

  //消息列表
  '标记已读': 'Marked Read',
  '确定删除这条消息吗？': 'Confirm to delete this news?',
  '查看详情 >': 'View More>',
  '暂时没有消息！': 'No news',
  '请先选择要操作的消息！': 'Please select the message you want to operate!',

  //品牌中心
  '品牌中心': 'Brand Center',


  //充值页面
  '1.填写充值金额': '1. Fill in the amount of recharge',
  '2.在线支付': '2. Online payment',
  '3.充值完成': '3. Complete recharge.',
  '填写充值金额': 'Fill in the amount of recharge',
  '充值账户': 'Recharging account.',
  '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账': 'Note: support Alipay payment, Wechat payment, after the success of online payment, recharge amount will be credited within 1-5 minutes',
  '温馨提示': 'Tips',
  '下一步': 'Next',
  '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；': '1.After recharge the balance credit may be delayed, but usually arrives within 5 minutes, any questions please contact customer service;',
  '2.充值金额输入值必须是不小于1且不大于5000的正整数；': '2. Enter the amount of recharge value from 1 to 5000 in positive integer numbers.',
  '3.充值完成后，您可至会员中心查看充值记录。': '3. After the recharge is complete, you can view the recharge record to the Member Center.',
  '请使用手机微信扫描下方二维码进行支付': 'Please scan the QR code with Wechat.',
  '您正在充值余额，请尽快支付': "You're recharge balance, please pay as soon as possible",
  '应付金额': 'Amounts payable',
  '微信支付支付': 'Wechat payment',
  '如二维码过期，': 'QR code expired.',
  '刷新': 'Refresh',
  '重新获取二维码。': 'Reacquire QR code.',
  '请输入充值金额': 'Please enter the recharge amount',
  '请选择支付方式': 'Please select the payment method',
  '充值成功,2s后自动跳转充值列表': 'Successful recharged, current page will automatically jump into recharge list after 2 seconds.',
  '立即充值': 'Recharge immediately',
  '选择充值方式': 'Recharge mode select',
  '使用微信扫码支付': 'Paymeny by Wechat.',
  '超过充值金额上限': 'Exceed the upper limit of the amount of recharge',


  //收货地址
  '地址管理': 'Address Management',
  '默认地址': 'Default address',
  '新增地址': 'New Address',
  '全部地址': 'All addresses',
  '暂无收货地址～': 'No shipping address ~',


  // 公共购物车顶部弹框组件
  '最新加入的商品': 'Items added recently',
  '去购物车': 'Go to shopping cart',


  //空页面组件
  '暂时没有数据～': 'No data',

  //手机号管理
  '当前手机号': 'Current phone number',
  '请输入新手机号': 'Please enter a new phone number',
  '为了保障您的账号安全，变更重要信息需进行身份验证。': 'To protect your account security, change critical information required for authentication.',
  '变更过程中有任何疑问请联系在线客服解决。': 'For any questions regarding to chage progress please contact with onliner service.',
  '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。': 'For any questions such as phone numbers and emails are not valid for receiving verification code please contact with onliner service.',


  //邮箱管理
  '请输入邮箱号': 'Please enter the email address',
  '请输入邮箱验证码': 'Please enter the email verification code',
  '请输入正确的邮箱验证码': 'Please enter a valid email address Verification Code',

  //设置登陆密码
  '复杂的密码可使账号更安全且建议定期更换密码。': 'Complex passwords make accounts more secure, and is recommended to change your password regularly.',

  //个人中心--会员信息
  '仅支持JPG、GIF、PNG格式；文件大小请在1.0MB之内。': 'Only supports JPG, GIF, PNG format; with size within 1.0MB.',
  '会员名：': 'Username:',
  '性别：': 'Gender:',
  '保密': 'Confidentiality',
  '男': 'Male',
  '女': 'Female',
  '昵称：': 'Nickname:',
  '生日：': 'Birthday:',
  '年': 'Year',
  '月': 'Month',
  '日': 'Day',
  '保存': 'save',

  //收货地址
  '设置为默认': 'Set as default',
  '删除地址': 'Delete address',
  '编辑地址': 'Edit address',

  //我的积分
  '日期': 'Date',
  '详细说明': 'Detailed description',
  '可用积分': 'Valid Credit',
  '收入': 'Income',
  '支出': 'Expense',
  '修改密码': 'Change Password',
  '请输入新密码': 'Please enter a new password',
  '请输入6～20位英文、数字、符号': 'Please input 6-20 English letters, numbers and symbols',
  '订单状态': 'Order Status',

  //确认下单页
  '积分使用数量为': 'The number of points used is:',
  '的整数倍': 'Integer multiple of',
  '确认订单': 'Confirm order',
  '发票信息': 'Invoice information',
  '开具发票': 'Issue Invoice',
  '店铺合计(含运费)': 'Total amount in the current shop (including freight)',
  '修改': 'Modify',
  '满': 'Full',
  '付款': 'Pay',
  '支付成功': 'Pay successful',
  '收货信息': 'Delivery information',
  '联系方式': 'Contact details',
  '收货地址': 'Shipping address',
  '更换地址': 'Change address',
  '新增收货地址': 'New address',
  '赠品': 'gifts',
  '赠完为止': 'until the gift is finished',
  '优惠券使用': 'coupon usage',
  '暂无可用优惠券': 'no coupons available',
  '不使用优惠券': 'Do not use coupons',
  '店铺宝贝': 'Customer Service',
  '小计': 'Subtotal',
  '重新选择': 'reselect',
  '订单备注': 'order notes',
  '给商家留言': 'Leave a message to the business',
  '商品合计': 'Total item',
  '运费': 'Freight',
  '积分抵扣': 'Credit deduction',
  '积分抵现': 'Credit use as cash',
  '当前积分': 'Current Credit',
  '我要开发票': 'I need Invoice',
  '实付款（含运费）': 'Real payment (including freight)',
  '优惠了': 'With discount',
  '返回购物车': 'Return to shopping cart',
  '实付款': 'Real payment',
  '寄送至': 'Sent to',
  '选择发票抬头': 'Select Invoice header',
  '新增发票': 'Add an invoice',
  '暂不开发票': 'Invoice not applied.',
  '发票内容': 'Invoice content',
  '商品类别': 'Product category',
  '发票内容将显示商品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额': 'The invoice content will display the item name and price information. The invoice amount is the actual payment amount, excluding deductions such as discounts',
  '发票内容将显示本单商品所属类别及价格信息，发票金额为实际支付金额，不含优惠等扣减金额。': 'The invoice content will display the category and price information of the items in this order. The invoice amount is the actual payment amount, excluding deductions such as discounts.',
  '请选择': 'Please select',
  '个人': 'Personal',
  '公司': 'Company',
  '发票类型': 'Invoice Type',
  '普通发票': 'General Invoice',
  '增值税专用发票': 'VAT special invoice',
  '单位名称': 'Company Name',
  '请在此填写单位名称': 'Please fill in the company name here',
  '税号': 'Tax ID',
  '请在此填写纳税人识别号': 'Please enter your taxpayer identification number here',
  "请输入注册地址": 'Please enter the registration address',
  "请输入注册电话": 'Please enter the registration phone',
  "请输入开户银行": 'Please enter the deposit bank',
  "请输入银行账户": 'Please enter bank account information',
  "请输入收票人姓名": "Please enter the name of the invoice recipient",
  "请输入收票人电话": "Please enter the phone number of the invoice recipient",
  "请输入收票人地址": "Please enter  invoice recipient address",
  '收票邮箱': 'Invoice receiveing email address',
  "设为默认发票": 'Set as default invoice',
  "返回": 'Return',
  '移除无货商品': 'Remove the out of stock items',
  '使用积分': 'Use Points',
  '店铺总优惠': 'Total shop discount',
  '使用': 'Use',
  '抵扣': 'Discount',
  '暂不使用积分': 'Points will not be used temporarily',
  '订单大于等于': 'Order is greater than or equal to',
  '元': 'Yuan',
  '可用': 'available',
  '积分支付不超过订单金额的': 'the credit payment does not exceed the order amount',
  '积分等于': 'Credit equals',
  "请填写发票抬头": 'Please fill in the invoice header',
  "请填写收票邮箱": 'Please fill in the invoice receiving email',
  "邮箱格式不正确，请重新输入！": 'The email format is incorrect, please re-enter!',
  "请填写单位名称": 'Please fill in the company name',
  "请填写纳税人税号": "Please fill in the taxpayer's tax number",
  "请填写正确的税号": 'Please fill in the correct tax number',
  "请填写注册地址": "Please fill in the registered address",
  "请填写注册电话": "Please fill in the Bank of deposit",
  "请填写开户银行": "Please fill in the bank account",
  "请填写银行账户": 'Please fill in the bank account',
  "请填写收票人姓名": "Please fill in the name of the invoice recipient",
  "请填写收票人电话": "Please fill in the phone number of the invoice recipient",
  "请填写收票人地址": "Please fill in the address of the invoice recipient",
  "提交订单失败，请稍后重试": 'Failed to submit the order, please try again later',
  ",2s后自动跳转订单列表": ", automatically jump to the order list after 2S",
  '请在此填写发票抬头': 'Please fill in the invoice header here',
  '请输入收票邮箱': 'Please enter the invoice receiving mailbox',
  '待付款': 'To be paid',
  '添加收货地址': 'Add shipping address',
  '手机号码': 'Phone Number',
  '所在地区': 'Location',
  '详细地址': 'Detailed Address',
  '设为默认地址': 'Set as default address',
  '请输入收货人姓名': 'Please enter the name of the consignee',
  '请选择所在地区': 'Please select your region',
  '请输入详细地址': 'Please enter detailed address',
  '保存并使用': 'Save and use',
  '店铺': 'Shop',
  '保 存': 'Save',

  '抬头类型': 'Headers type',
  '发票介质': 'Invoices type',
  '电子发票（开票快）': 'Electronic invoice',
  '纸质发票': 'Paper invoice',
  '某某某科技有限公司': 'XX Technology Co., Ltd.',
  '纳税人识别号': 'Tax ID',
  '基本开户银行': 'Deposit bank',
  '请输入基本开户银行': 'Please enter the deposit bank',
  '基本开户账号': 'Account number',
  '请输入基本开户账号': 'Please enter the deposit account number',
  '企业注册地址': 'Registered address',
  '请输入企业注册地址': 'Please enter the registered address of the company',
  '企业注册电话': 'Registered phone',
  '请输入企业注册电话': 'Please enter the company registered phone number',
  '邮箱名称': 'Email address',
  '请输入邮箱名称': 'Please enter email address',
  '请选择收货地址': 'Please select a delivery address',
  '票据信息': 'Invoice information',
  '邮箱信息': 'Email information',
  '邮寄信息': 'Mailing information',
  '请选择发票介质': 'Please select an invoice type',
  '请选择发票内容': 'Please select the invoice content',
  '使用其它地址': 'Use another address',

  // 支付页
  '订单提交成功，只差付款了～': 'The order has been submitted successfully, only the payment is needed~',
  '请您在提交订单后': 'Please after submit your order',
  '24小时内': 'Within 24 hours',
  '完成支付，否则订单会自动取消': 'to complete the payment, otherwise the order will be automatically cancelled',
  '收起详情': 'Collapse details',
  '展开详情': 'Expand details',
  '使用余额支付': 'Pay with balance',
  '余额支付': 'Balance payment',
  '其他支付': 'Other payment',
  '目前需要在线支付': 'Online payment is currently required',
  '余额不足': 'Insufficient balance',
  '马上充值': 'Recharge now',
  '未设置支付密码，马上去设置': 'The payment password has not been set, set immediately',
  '选择其它支付方式': 'Choose another payment method',
  '微信支付': 'WeChat Pay',
  '扫码完成支付': 'Scan the code to complete the payment',
  '暂无可用的支付方式，平台正在紧急处理中～': 'There is no available payment method at the moment, the platform is urgently processing~',

  // 店铺列表
  '销量排序': 'sales ranking',
  '人气排序': 'popularity ranking',
  '入驻时间': 'Settled Date',
  '个结果': 'Results',
  '查看更多商品': 'More Items',
  '查看店铺': 'Shops',
  '在线客服': 'Online Service',
  '查看资质': 'View Qualification',
  '商家类型': 'Business type',
  '所在地': 'Location',
  '经营类目': 'Business category',
  '本店暂无商品': 'No Item',

  '供货商': 'Supplier',
  '生产厂家': 'Factory of production',
  '航食公司': 'Airline Food Company',
  '机场店铺': 'Airport Shop',
  '航展主办方': 'Air Show Organizer',
  '出行': 'Travel',
  '飞行教育基地': 'Flight Education Base',
  '高端旅行社': 'High-end travel agency',
  '文创店': 'Cultural creation shop',
  '承运商': 'Carrier of goods',
  '承修商': 'Contractor of repair',
  '航空公司': 'Airlines',

  //店铺装修
  请输入商品名或店铺名:'Please enter the product name or store name',
  商品买点:'Shopping for goods',


};
