/**
 *  项目的配置文件
 */
// export const apiUrl = "https://data.caacmall.com/"; //接口请求地址  线上
export const apiUrl = "https://www.caacmall.cn/"; //接口请求地址  测试
// export const apiUrl = "http://www.caacmall.net/"; //接口请求地址  开发
// export const apiUrl = "/pc"; //接口请求地址
export const curLang = localStorage.getItem('languageType') || "zh"; //当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
// export const chatUrl = "wss://im.caacmall.com"; //客服地址 线上
export const chatUrl = "wss://im.caacmall.cn"; //客服地址 测试
// export const chatUrl = "wss://im.caacmall.net"; //客服地址 开发
export const mUrl = "https://www.caacmall.cn/"; //移动端网页地址
// export const mUrl = "http://h5.caacmall.net/"; //移动端网页地址
export const title = "民航商城"; //浏览器顶部title
export const gdKey = "e8f10ce99a783e76291a067ffc297aef"; //高德web-js key
export const securityJsCode = "4ffd86746f15283c6d8600ad0eaa047b"; //高德web-js 安全密钥
export const statShowDebug = false; //是否开启统计的调试

// ** copyright *** slodon *** version-v3.6 *** date-2021-12-17 ***主版本v3.6
