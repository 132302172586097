<template>
  <router-view />
  <ZiXun></ZiXun>
</template>

<script>
import ZiXun from "@/components/ZiXun";
export default {
  name: 'App',
  components:{
    ZiXun
  }
}
</script>

<style lang="scss">
  @import "./style/reset.scss";
  @import "./style/base.scss";
  //放大镜位置
  .mouse-cover-canvas {
    position: absolute;
    top: 173px !important;
    left: 740px !important;
  }

  .article_content img{
	  max-width: 853px;
	  object-fit: contain;
  }
</style>
