<template>
  <div class="ZiXun">
    <div class="z_wrap">
      <div class="z_box box_up" @click="handleGoKeFu">
        <div class="box_img">
          <img src="@/assets/index/zixun1.png" alt="">
        </div>
        <div>官方客服</div>
      </div>
      <div class="line"></div>
      <div class="z_box box_down">
        <div class="box_img">
          <img src="@/assets/index/zixun2.png" alt="">
        </div>
        <div>电话咨询</div>
        <div class="phone">
          咨询电话：{{configInfo.basic_site_phone}}
          <img src="@/assets/index/right.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import {onMounted, computed, ref, getCurrentInstance} from 'vue'
import {useStore} from "vuex";
export default {
  name: 'ZiXun',
  components: {
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute()
    const L = proxy.$getCurLanguage();
    const store = useStore();
    const isShow = computed(() => {
      return route.fullPath == '/index'
    })
    const configInfo = ref(store.state.configInfo)

    onMounted(() => {
      console.log(route,router,'pppp11111')
    })

    const handleGoKeFu = () => {
      let chatInfo = {
        storeId: 0,
        vendorAvatar: configInfo.value.platform_customer_service_logo,
        storeName: configInfo.value.platform_customer_service_name,
        showData: {},
        source: ''
      }
      console.log(configInfo.value, 123)
      store.commit('saveChatBaseInfo', chatInfo)

      let newWin = router.resolve({
        path: '/service',
        query: {vid:0}
      })

      window.open(newWin.href, '_blank')
    }

    return { L, isShow, configInfo, handleGoKeFu }

  }
}
</script>

<style lang="scss" scoped>
.ZiXun {
  position: fixed;
  right: 20px;
  top: 65vh;
  width: 80px;
  height: 160px;
  color: #fff;
  z-index: 1;
  border-radius: 4px;
  background-color: #1861f2;
  opacity: .9;
  .z_wrap{
    width: 80px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .z_box{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 100%;
      //width: 120px;
      line-height: 18px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      .box_img{
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .line{
      width: 70px;
      height: 1px;
      background-color: #fff;
    }
    .box_down:hover{
      width: 100%;
      height: 100%;
      padding-top: 1px;
      background-color: #1879f2;
      border-radius: 4px;

      .phone{
        visibility:visible;
        opacity: 1;
        left: -220px;
        transition: all .3s;
      }
    }
    .phone{
      position: absolute;
      left: -200px;
      opacity: 0;
      bottom: 10px;
      color:#1861f2;
      width: 200px;
      height: 60px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: -1px 1px 10px #999;
      text-align: center;
      line-height: 60px;
      visibility:hidden;
      img{
        position: absolute;
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
      }
    }
  }

}
</style>
